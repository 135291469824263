import React from "react";
import { Box } from "@chakra-ui/react";
import { AnimatedContent } from "../components/RouteLink";
import { useAuth } from "../core/hooks/useAuth";

import Hero from "../views/Industries/Hero";
import FundsInfo from "../views/Industries/FundsInfo";
import Strategy from "../views/Industries/Strategy";
// import Profile from "../views/Industries/Profile";

function Funds() {
  const { isLogged } = useAuth();

  return (
    <Box>
      <Hero />
      <AnimatedContent>
        <FundsInfo />
        <Strategy />
        {/* {!isLogged && <Profile />} */}
      </AnimatedContent>
    </Box>
  );
}

export default Funds;
