import React from "react";
import { Box } from "@chakra-ui/react";
import { useTranslation } from "../../../core/hooks/useTranslation";
import ViewportAnimation from "../../../components/ViewportAnimation";
import PageContainer from "../../../components/PageContainer";
import ContentTitle from "../../../components/ContentTitle";
import imgLogo from "../../../images/investment-icon.svg";
import animation from "./_animation";

function Strategy() {
  const { language, t } = useTranslation();

  return (
    <Box
      minHeight="viewFundsStrategyH"
      paddingY="viewFundsStrategyP"
      background="light.400"
      position="relative"
    >
      <PageContainer lessWidth={true}>
        <Box maxWidth={{ base: "", xl: "viewFundsStrategyCardW" }}>
          <ViewportAnimation {...animation.text}>
            <ContentTitle
              titleBold={t("page@industries@strategy@title-1")}
              titleLight={t("page@industries@strategy@title-2")}
              order={"en-US" !== language ? "break" : "inline"}
            >
              <p style={{ marginBottom: "25px" }}>
                {t("page@industries@strategy@text-top")}
              </p>

              <ul style={{ paddingLeft: "15px" }}>
                <li>{t("page@industries@strategy@item-1")}</li>
                <li>{t("page@industries@strategy@item-2")}</li>
                <li>{t("page@industries@strategy@item-3")}</li>
                <li>{t("page@industries@strategy@item-4")}</li>
                <li>{t("page@industries@strategy@item-5")}</li>
                <li>{t("page@industries@strategy@item-6")}</li>
              </ul>

              <p style={{ marginTop: "25px" }}>
                {t("page@industries@strategy@text-bottom")}
              </p>
            </ContentTitle>
          </ViewportAnimation>
        </Box>

        <Box
          position="absolute"
          top="-viewFundsStrategyP"
          right="0"
          height="viewFundsStrategyImgH"
          width="viewFundsStrategyImgW"
          marginTop="-viewFundsStrategyImgOut"
          display={{ base: "none", md: "block" }}
        >
          <ViewportAnimation {...animation.image}>
            <Box
              backgroundImage={imgLogo}
              backgroundRepeat="no-repeat"
              filter="grayscale(1)"
              opacity="0.3"
              width="100%"
              height="viewFundsStrategyImgH"
            />
          </ViewportAnimation>
        </Box>
      </PageContainer>
    </Box>
  );
}

export default Strategy;
