import React from "react";
import { Box } from "@chakra-ui/react";
import { useTranslation } from "../../../core/hooks/useTranslation";
import PageParallax from "../../../components/PageParallax";
import ViewportAnimation from "../../../components/ViewportAnimation";
import PageContainer from "../../../components/PageContainer";
import ContentTitle from "../../../components/ContentTitle";
import CardContainer from "../../../components/CardContainer";
import imgFunds from "../../../images/funds-details.png";
import animation from "./_animation";

function FundsInfo() {
  const { t } = useTranslation();

  return (
    <Box
      position="relative"
      paddingTop="viewFundsFundsT"
      paddingBottom="viewFundsFundsB"
    >
      <Box position="relative" zIndex="2">
        <PageContainer>
          <Box position="relative" minHeight="viewFundsFundsCardH">
            <Box
              position={{ base: "", lg: "absolute" }}
              left={{ base: "", lg: "0" }}
              margin={{ base: "0 auto", lg: "0" }}
              width={{
                base: "viewFundsFundsCardWSmall",
                lg: "viewFundsFundsCardW",
              }}
              height={{
                base: undefined,
                lg: "viewFundsFundsCardH",
              }}
            >
              <ViewportAnimation {...animation.cardImage}>
                <PageParallax y={["-15%", "-10%"]}>
                  <CardContainer
                    spacing={false}
                    boxShadow="0 12px 44px 0 rgba(0,0,0,0.05)"
                  >
                    <Box
                      width={{
                        base: "viewFundsFundsCardWSmall",
                        lg: "viewFundsFundsCardW",
                      }}
                      height={{
                        base: "viewFundsFundsCardHSmall",
                        lg: "viewFundsFundsCardH",
                      }}
                      // background="blue.600"
                      backgroundImage={imgFunds}
                      backgroundRepeat="no-repeat"
                      backgroundSize="cover"
                    />
                  </CardContainer>
                </PageParallax>
              </ViewportAnimation>
            </Box>

            <Box
              position={{ base: "", lg: "absolute" }}
              right={{ base: "", lg: "0" }}
              width={{ base: "", lg: "viewFundsFundsCardW" }}
              height={{
                base: undefined,
                lg: "viewFundsFundsCardH",
              }}
              paddingTop={{
                base: "viewFundsFundsCardPSm",
                xl: "viewFundsFundsCardP",
              }}
            >
              <ViewportAnimation {...animation.text}>
                <ContentTitle title={t("page@industries@info@title")} spaces="6">
                  {t("page@industries@info@line-1")}
                  <br />
                  <br />
                  {t("page@industries@info@line-2")}
                </ContentTitle>
              </ViewportAnimation>
            </Box>
          </Box>
        </PageContainer>
      </Box>

      <Box
        position="absolute"
        zIndex="1"
        marginTop="viewFundsFundsT"
        top={{
          base: "viewFundsFundsCardPSm",
          xl: "viewFundsFundsCardP",
        }}
        left="0"
        width={{
          base: "75%",
          lg: "50%",
          xl: "50%",
        }}
        height="viewFundsFundsGradientH"
      >
        <ViewportAnimation {...animation.cardGradient}>
          <PageParallax y={["-40%", "30%"]}>
            <Box
              width="100%"
              height="viewFundsFundsGradientH"
              left="0"
              bgGradient="linear(90deg, blue.1000 0%, blue.1100 100%)"
              borderTopRightRadius="card"
              borderBottomRightRadius="card"
            />
          </PageParallax>
        </ViewportAnimation>
      </Box>
    </Box>
  );
}

export default FundsInfo;
