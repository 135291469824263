import React, { useMemo } from "react";
import { Box } from "@chakra-ui/react";

function CardContainer({ spacing, borders, children, ...moreProps }) {
  const { bTopLeft, bTopRight, bBottomLeft, bBottomRight } = useMemo(
    () => ({
      bTopLeft: (borders || []).includes("tl"),
      bTopRight: (borders || []).includes("tr"),
      bBottomLeft: (borders || []).includes("bl"),
      bBottomRight: (borders || []).includes("br"),
    }),
    [borders]
  );

  return (
    <Box
      {...moreProps}
      paddingY={spacing ? "cardY" : undefined}
      paddingX={spacing ? "cardX" : undefined}
      borderTopLeftRadius={bTopLeft ? "card" : "0"}
      borderTopRightRadius={bTopRight ? "card" : "0"}
      borderBottomLeftRadius={bBottomLeft ? "card" : "0"}
      borderBottomRightRadius={bBottomRight ? "card" : "0"}
      overflow="hidden"
    >
      {children}
    </Box>
  );
}

CardContainer.defaultProps = {
  spacing: true,
  borders: ["tl", "tr", "bl", "br"],
};

export default CardContainer;
