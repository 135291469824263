const textAnimation = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    top: "2.3rem",
  },
  animate: {
    opacity: 1,
    top: 0,
  },
  transition: {
    duration: 0.82,
    delay: 0.5,
  },
};

const imageAnimation = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    top: "2.3rem",
  },
  animate: {
    opacity: 1,
    top: 0,
  },
  transition: {
    duration: 0.75,
    delay: 0.72,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  text: textAnimation,
  image: imageAnimation,
};
